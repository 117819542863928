import React from 'react'

const Footer = () => (
  <section className="footer">
    <div className="container">
      <p><strong>Hi, ik ben Simon Koelewijn</strong><br />
      Het hele jaar heb ik slimme interfaces en UX-tips verzameld, en tot en met 5 december deel ik deze hier. Volg me op Twitter: <a href="https://twitter.com/simonk/">@simonk</a></p>
    </div>
  </section>
)

export default Footer

import React from 'react'
import { Link } from 'gatsby'

const Navbar = () => (
  <nav className="navbar is-transparent">
    <div className="container">
        <h1 className="navbar-brand">
          <Link to="/" className="navbar-item">
            Sinterface
          </Link>
        </h1>
        <div className="navbar-start">
          Tot en met 5 december iedere dag een gratis tip in je SVG-schoen om bezoekers van je website blij(er) te maken.
        </div>
        <div className="navbar-end">
        </div>
    </div>
  </nav>
)

export default Navbar

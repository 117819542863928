import React from 'react';
import './script.js';
import './style.sass';

// 291435/zlhvvp
class Subscribe extends React.Component {
  render() {
    return (
      <section className="subscribe">
        <div className="container">
          <div className="subscribe-input" id="subscribe-input">
            <form action="https://hooks.zapier.com/hooks/catch/2572697/e7i8k0/" method="post" id="subscribe" name="subscribe" target="_blank">
              <h3>Volgend jaar niks missen?</h3>
              <p>Sinterface komt terug in 2019. Zet je schoen en schrijf je nu alvast in.</p>
              <input type="name" name="name" id="name" placeholder=""/>
              <input type="email" name="email" id="email" placeholder="E-mailadres" className="input-text" required/>
              <button type="submit" name="subscribe-button" id="subscribe-button" className="button">Abonneren</button>
            </form>
          </div>
          <div className="subscribe-success hidden" id="subscribe-success">
            <h3>Bedankt!</h3>
            <p id="gmail" className="hidden">Bijna klaar, ga naar je <a href="https://mail.google.com/mail/u/0/#inbox">Gmail-inbox om je inschrijving te bevestigen.</a></p>
            <p id="outlook" className="hidden">Bijna klaar, ga naar je <a href="https://outlook.live.com/owa/">Outlook-inbox om je inschrijving te bevestigen.</a></p>
            <p id="hotmail" className="hidden">Bijna klaar, ga naar je <a href="https://outlook.live.com/owa/">Hotmail-inbox om je inschrijving te bevestigen.</a></p>
            <p id="icloud" className="hidden">Bijna klaar, ga naar je <a href="https://www.icloud.com/#mail">iCloud-inbox om je inschrijving te bevestigen.</a></p>
            <p id="other" className="hidden">Bijna klaar, ga naar je inbox om je inschrijving te bevestigen.</p>
          </div>
        </div>
      </section>
    );
  }
}

export default Subscribe;


if (typeof document !== "undefined") {
	// Check all fields on submit
	document.addEventListener('submit', function (event) {

		// Prevent form from submitting
		event.preventDefault();

		// Get all of the form elements
		var fields = event.target.elements;
		var email = fields.email.value;
		var name = fields.name.value;

		if (name.length > 0) {
			return;
		}

		try {
			var xhr = new XMLHttpRequest();
			// 2572697/e7i8k0/
			xhr.open("POST", "https://hooks.zapier.com/hooks/catch/2572697/e7i8k0/");
			xhr.send(JSON.stringify({ email: email }));
			document.getElementById('subscribe-input').classList.add("hidden");
			document.getElementById('subscribe-success').classList.remove("hidden");
			if (email.indexOf('@gmail.com') > -1 || email.indexOf('@frankwatching.com') > -1) {
				document.getElementById('gmail').classList.remove('hidden');
			} else if (email.indexOf('@outlook.com') > -1) {
				document.getElementById('outlook').classList.remove('hidden');
			} else if (email.indexOf('@hotmail.com') > -1) {
				document.getElementById('hotmail').classList.remove('hidden');
			} else if (email.indexOf('@icloud.com') > -1 || email.indexOf('@me.com') > -1) {
				document.getElementById('icloud').classList.remove('hidden');
			} else {
				document.getElementById('other').classList.remove('hidden');
			}
		} catch (e) {
			console.error(e);
		}

	}, false);

}
